import { AnchorViewProps } from "../models";
import { Show, batch, createEffect, createMemo, splitProps } from "solid-js";
import { useLocation, useSearchParams } from "@solidjs/router";
import { SHARED_UTILS } from ":mods";
import { actions } from "../store";

export function Anchor(props: AnchorViewProps) {
  const [local, others] = splitProps(props, [
    "title",
    "icon",
    "to",
    "searchParams",
    "activeOnSearchParams",
    "onClick",
    "activeClass",
    "onActive",
    "class",
    "titleClass",
    "subtitleClass",
  ]);
  const [search_params, setParams] = useSearchParams();
  const $location = useLocation();
  let href = undefined;
  if (local.to !== undefined && local.to !== null) {
    href = SHARED_UTILS.populateRouteHref(local.to);
  }
  const $isActive = createMemo(() => {
    const link_active = local.to && $location.pathname.includes(href);
    let search_params_active = false;
    if (local.activeOnSearchParams) {
      let total = 0;
      let active = 0;
      for (const p in local.activeOnSearchParams) {
        const value = String(local.activeOnSearchParams[p]);
        if (search_params[p] === value) {
          active++;
        }
        total++;
      }
      if (active === total) {
        search_params_active = true;
      }
    }
    if (!link_active && !search_params_active) {
      return false;
    }
    local.onActive?.();
    return true;
  });
  const $title_subtitle = createMemo(() => {
    if (!local.title) {
      return undefined;
    }
    const obj = typeof local.title === "string" ? { title: local.title } : local.title;
    return obj;
  });
  const $icon = createMemo(() => {
    if (!local.icon) {
      return undefined;
    }
    const obj = typeof local.icon === "string" ? { idle: local.icon } : local.icon;
    return obj;
  });

  // createEffect(() => {
  //   if ($isActive()) {
  //     // console.log("is active anchot :: ", local.activeOnSearchParams);
  //   }
  // });

  return (
    <div
      {...others}
      class={`relative cursor-pointer ${local.class ?? ""}`}
      onClick={() => {
        batch(() => {
          if (local.to) {
            actions.navigateHref(local.to);
          }
          // console.warn("setting search params :: ", local.searchParams);
          if (local.searchParams) {
            setParams(local.searchParams, { replace: true });
          }
        });
        local.onClick?.();
      }}
      classList={{
        [local.activeClass ?? "active"]: $isActive(),
      }}
    >
      <Show when={$icon() && $icon()?.idle?.length > 0}>
        <i class={`w-24px h-24px ${!$isActive() ? $icon().idle : $icon().active ?? $icon().idle}`} />
      </Show>
      <div class="title-group">
        {$title_subtitle()?.title && <span class={`title ${local.titleClass ?? ""}`}>{$title_subtitle()?.title}</span>}
        {$title_subtitle()?.subtitle && (
          <span class={`subtitle ${local.subtitleClass ?? ""}`}>{$title_subtitle()?.subtitle}</span>
        )}
      </div>
    </div>
  );
}
